<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 70px">
    <w-message :message="message" ref="messages"></w-message>
    <main class="page-content w-99">
      <div class="container-fluid row">
        <ul
          class="breadcrumb ProximaNovaRegular col"
          style="margin-left: 52px; margin-top: 12px"
        >
          <li>
            <span>Sales Statistics</span>
          </li>
          <li>
            <span>Long term level</span>
          </li>
          <li>
            <span>Future prediction</span>
          </li>
          <li>
            <span>Scenerio planning</span>
          </li>
          <li class="ProximaNovaBold">Planning Results</li>
        </ul>
      </div>
      <div class="float-left bottom_buttom">
        <button
          color="primary isi"
          class="btn-effect mr-5 active arrow-left-btn"
          v-if="isSavedPlan"
          size="button--size-m"
        >
          <i
            class="fas fa-arrow-right ml-5 pl-4 d-flex"
            style="align-items: center"
          ></i>
        </button>
      </div>
      <div class="float-right bottom_buttom buttons-section">
        <button
          color="primary isi"
          class="btn-effect-default mr-5 active"
          size="button--size-m"
          v-if="isSavedPlan"
        >
          Scenario Planning
        </button>
        <button
          color="primary isi"
          class="btn-effect mr-5 active ml-0"
          size="button--size-m"
          @click="savePlan"
        >
          Save Plan<i
            class="fas fa-arrow-right ml-5 pl-4 d-flex"
            style="align-items: center"
          ></i>
        </button>
      </div>
      <div class="w-100 pb-4">
        <div
          class="container-fluid tab-panel"
          style="margin-top: 36px; padding-left: 40px; padding-right: 30px"
        >
          <div class="card w-100">
            <div class="card-body p-0">
              <div>
                <w-chartoverview :chartSummary="chartSummary"></w-chartoverview>
              </div>
            </div>
          </div>

          <!-- Level 1 - Previous Vs Predicted Budget -->
          <div class="card w-100 mt-5">
            <div class="card-body row">
              <div class="col-12 fit-content">
                <ColumnChart :chartData="columnChartData" />
              </div>
            </div>
          </div>

          <!-- <div
              class="card w-100 mt-5"
            >
              <div class="card-body">
                <Digital
                  :data="digitalExpandData"
                  :stripData="digitalData"
                  total="75"
                />
              </div>
            </div> -->

          <div v-for="(data, index) in mediaData" :key="index">
            <div class="card my-5">
              <div class="card-body pt-4 px-5">
                <Digital
                  v-if="data.type === 'range'"
                  :title="data.label"
                  :data="data.otherChartData"
                  :stripData="data.stripData"
                  @getDataBreakdown="getDataBreakdown"
                  :dot="data.backgroundColor"
                />
                <Content
                  v-if="data.type === 'bar'"
                  :title="data.label"
                  :barData="data.otherChartData"
                  :stripData="data.stripData"
                  :dot="data.backgroundColor"
                />
              </div>
            </div>
          </div>

          <div class="card w-100 mt-5">
            <div class="card-body px-5">
              <Content
                :data="contentExpandData"
                :stripData="contentData"
                total="35"
              />
              <!-- <div class="col-12 fit-content">
                <div class="mb-5">
                  <StripChart
                    :chartData="contentData"
                    @getBreakdown="getContentBreakdown"
                    :breakdown="contentBreakDown"
                    :showBreakdown="true"
                    total="75"
                    title="Content"
                    subtitle="Recommended Content Mix"
                  />
                </div>
                <div
                  v-if="contentBreakDown"
                  class="border_top_divider_last pt-5"
                >
                  <x-range-chart :series="contentExpandData" />
                </div>
              </div> -->
            </div>
          </div>

          <div class="card w-100 mt-5">
            <div class="card-body row">
              <div class="col-12 fit-content">
                <div>
                  <LineChart :chartData="lineChartData" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal
        name="newSavedPlanDetail"
        class="welcome--modal"
        :clickToClose="true"
        @closed="hideSavedPlanDetail"
      >
        <div class="" style="width: 100%">
          <div class="w-100">
            <div class="row modal-padding">
              <div class="col p-0 welcome ml-4 mt-4 align-start">
                <img src="@/assets/group-21.png" alt class="welcome--image" />
              </div>
              <div class="col p-0 intro__content">
                <p class="Welcome-to-Data-Poem ProximaNovaRegular">
                  Save plan details
                </p>
                <p
                  class="Together-let-us-hel ProximaNovaRegular mt-0 w-100"
                  style="height: auto"
                >
                  <b-form-input
                    type="email"
                    class="form-control input-control c-form-input m-0 w-100"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder=""
                    name="example-input-1"
                  ></b-form-input>
                  <w-dropdown
                    :options="saveplandropdown"
                    :placeHolder="'Select'"
                    :labelText="'Plan saved in'"
                    class="mt-5"
                    @input="selectSavePlan($event)"
                  ></w-dropdown>
                </p>
                <div class="btn-right w-100" style="height: auto">
                  <button
                    color="primary isi"
                    class="
                      btn-effect-intro
                      width-btn-save-plan
                      mt-5
                      float-right
                    "
                    size="button--size-m"
                    @click="savedPlan"
                  >
                    <span class="btn-text">Save Plan</span>
                    <i
                      class="fas fa-arrow-right ml-5 pl-4 d-flex"
                      style="align-items: center"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </main>
  </div>
</template>

<script>
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
// import XRangeChart from "@/components/Chart/XRangeChart.vue";
// import StripChart from "@/components/Chart/StripChart.vue";
import LineChart from "@/components/Chart/LineChart.vue";
import Dropdown from "@/widgets/Dropdown.vue";
import Message from "@/components/Message/Message.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";

export default {
  name: "PlanningResults",
  components: {
    "w-chartoverview": ChartOverview,
    ColumnChart,
    // "x-range-chart": XRangeChart,
    // StripChart,
    LineChart,
    "w-dropdown": Dropdown,
    "w-message": Message,
    Digital,
    Content
  },
  data() {
    return {
      chartData: [
        {
          data: [
            12717460.54, 828952.64, 43152747.05, 20776293.79, 859279.67,
            1025976.67, 1295805.46, 22173201.93, 15326479.16, 1128772.48,
            33291472.55, 17620150.98, 17620150.98, 69174921.13, 10882356.31,
            25761648.53, 20303362.18, 719149.93, 13948676.36, 30638403.3,
            14131204.31, 27556188.13, 11583731.42, 26395318.31, 41228745.64,
            23783496.06, 64448912.62,
          ],
          color: "#050505",
          name: "Actual sales",
        },
        {
          data: [
            8890882.18, 1796251.56, 36012416.25, 18436551.34, 1997110.66,
            3250937.22, 2523196.53, 22605241.45, 12501831.64, 2222617.3,
            33337613.37, 24758605.76, 24758605.76, 64728860.68, 15149676.04,
            40452797.3, 27645835.05, 387327.02, 18766988.55, 23019635.38,
            14805686.79, 21844279.72, 11217601.82, 25370013.4, 52285003.39,
            21844279.72, 60943676.84,
          ],
          color: "#67e8ff",
          name: "Predicted sales",
        },
      ],
      chartSummary: [
        {
          name: "Previous Revenue",
          value: "$568M",
          className: "blue-pastel",
        },
        {
          name: "Previous Revenue",
          value: "$768M",
          className: "green-pastel",
        },
        {
          name: "Previous Revenue",
          value: "$768M",
          className: "pink-pastel",
        },
        {
          name: "Previous Revenue",
          value: "$568M",
          className: "red-pastel",
        },
        {
          name: "Previous Revenue",
          value: "$268M",
          className: "yellow-pastel",
        },
        {
          name: "Previous Revenue",
          value: "$268M",
          className: "purple-pastel",
        },
      ],
        lineChartData: {
        series: [
          {
            data: [
              { x: Date.UTC(2020, 1, 31), y: 1 },
              { x: Date.UTC(2020, 2, 31), y: 3 },
              { x: Date.UTC(2020, 3, 31), y: 15 },
              { x: Date.UTC(2020, 4, 31), y: 4 },
              { x: Date.UTC(2020, 5, 31), y: 15 },
              { x: Date.UTC(2020, 6, 31), y: 14 },
              { x: Date.UTC(2020, 7, 31), y: 23 },
              { x: Date.UTC(2020, 8, 31), y: 42 },
              { x: Date.UTC(2020, 9, 31), y: 25 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Previous Spend",
          },
          {
            data: [
              { x: Date.UTC(2020, 1, 31), y: 10 },
              { x: Date.UTC(2020, 2, 31), y: 3 },
              { x: Date.UTC(2020, 3, 31), y: 55 },
              { x: Date.UTC(2020, 4, 31), y: 4 },
              { x: Date.UTC(2020, 5, 31), y: 1 },
              { x: Date.UTC(2020, 6, 31), y: 40 },
              { x: Date.UTC(2020, 7, 31), y: 27 },
              { x: Date.UTC(2020, 8, 31), y: 7 },
              { x: Date.UTC(2020, 9, 31), y: 2 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Predicted Spend",
          },
        ],
        title: {
          text: "Media - Previous vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Recommended Budget Mix",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "sales growth %",
            style: {
              color: "#8394b4",
            },
          },
        },
        xAxis: {
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          type: "datetime",
        },
        colors: ["#050505", "#18ffff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      columnChartData: {
        data: [
          {
            name: "Digital",
            data: [75, 100],
          },
          {
            name: "Content",
            data: [45, 50],
          },
        ],
        title: {
          text: "Level 1 - Previous Vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Budget",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#ffdd7c"],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 20,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        xAxis: {
          categories: ["Previous", "Predicted"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      mediaData: [
        {
          label: "Digital",
          value: 35,
          backgroundColor: "#8e24aa",
          type: "range",
          stripData: [
            {
              label: "Google Analytics",
              value: 35,
              backgroundColor: "#8e24aa",
            },
            {
              label: "Facebook",
              value: 23,
              backgroundColor: "#8e24aa",
            },
            {
              label: "DV 360",
              value: 5,
              backgroundColor: "#8e24aa",
            },
            {
              label: "Linkedin",
              value: 9,
              backgroundColor: "#8e24aa",
            },
            {
              label: "Instagram",
              value: 15,
              backgroundColor: "#8e24aa",
            },
            {
              label: "Twitter",
              value: 15,
              backgroundColor: "#8e24aa",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "Television",
          value: 23,
          backgroundColor: "#d81b60",
          type: "bar",
          stripData: [
            {
              label: "Cable Tv",
              value: 35,
              backgroundColor: "#d81b60",
            },
            {
              label: "Local Tv",
              value: 44,
              backgroundColor: "#d81b60",
            },
            {
              label: "Broadcast Tv",
              value: 24,
              backgroundColor: "#d81b60",
            },
          ],
          otherChartData: {
            height: 400,
            colors: [
              "#ffdd7c",
              "#b5a9ff",
              "#ffa2a2",
              "#fdbfff",
              "#64E572",
              "#FF9655",
              "#FFF263",
              "#6AF9C4",
            ],
            title: {
              text: "",
            },
            xAxis: {
              categories: ["Television"],
              title: {
                text: null,
              },
              labels: {
                enabled: false,
                style: {
                  color: "#8394b4",
                  fontSize: "16px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: [
              {
                lineWidth: 1,
                title: {
                  text: null,
                },
                labels: {
                  enabled: false,
                },
                gridLineWidth: 0,
              },
              {
                min: 0,
                max: 100,
                title: {
                  text: "",
                  align: "high",
                },
                gridLineDashStyle: "shortdash",
                labels: {
                  overflow: "justify",
                  style: {
                    color: "#8394b4",
                  },
                  formatter: function () {
                    var label = this.axis.defaultLabelFormatter.call(this);
                    return label + "%";
                  },
                },
                opposite: true,
                tickInterval: 25,
              },
            ],
            tooltip: {
              borderColor: "#FFFFFF",
              backgroundColor: "#050505",
              color: "#d0dbee",
              borderWidth: 0,
              style: {
                color: "#d0dbee",
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: false,
                },
                pointPadding: 0.3,
                // colorByPoint: true,
              },
              showInLegend: true,
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            legend: {
              enabled: true,
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 24,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "GEC",
                data: [10],
                showInLegend: true,
              },
              {
                name: "Comedy",
                data: [23],
              },
              {
                name: "Reality show",
                data: [48],
              },
              {
                name: "News",
                data: [38],
              },
              {
                name: "Sports",
                data: [78],
              },
            ],
          },
        },
        {
          label: "Event sponsership",
          value: 5,
          backgroundColor: "#1e88e5",
          type: "range",
          stripData: [
            {
              label: "Sports",
              value: 75,
              backgroundColor: "#1e88e5",
            },
            {
              label: "Tv Shows",
              value: 25,
              backgroundColor: "#1e88e5",
            },
          ],
          otherChartData: [
            {
              name: "NFL",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "NBL",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "MLB",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "Radio",
          value: 9,
          backgroundColor: "#e53935",
          type: "bar",
          stripData: [
            {
              label: "NewYork",
              value: 35,
              backgroundColor: "#e53935",
            },
            {
              label: "Burbank",
              value: 23,
              backgroundColor: "#e53935",
            },
            {
              label: "Atlanta",
              value: 5,
              backgroundColor: "#e53935",
            },
            {
              label: "San Francisco",
              value: 9,
              backgroundColor: "#e53935",
            },
            {
              label: "Philadelphia",
              value: 15,
              backgroundColor: "#e53935",
            },
            {
              label: "Minneapolis",
              value: 15,
              backgroundColor: "#e53935",
            },
            {
              label: "Chicago",
              value: 15,
              backgroundColor: "#e53935",
            },
            {
              label: "Detroit",
              value: 13,
              backgroundColor: "#e53935",
            },
          ],
          otherChartData: {
            height: 400,
            colors: [
              "#ffdd7c",
              "#b5a9ff",
              "#ffa2a2",
              "#fdbfff",
              "#64E572",
              "#FF9655",
              "#FFF263",
              "#6AF9C4",
            ],
            title: {
              text: "",
            },
            xAxis: {
              categories: ["Television"],
              title: {
                text: null,
              },
              labels: {
                enabled: false,
                style: {
                  color: "#8394b4",
                  fontSize: "16px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: [
              {
                lineWidth: 1,
                title: {
                  text: null,
                },
                labels: {
                  enabled: false,
                },
                gridLineWidth: 0,
              },
              {
                min: 0,
                max: 100,
                title: {
                  text: "",
                  align: "high",
                },
                gridLineDashStyle: "shortdash",
                labels: {
                  overflow: "justify",
                  style: {
                    color: "#8394b4",
                  },
                  formatter: function () {
                    var label = this.axis.defaultLabelFormatter.call(this);
                    return label + "%";
                  },
                },
                opposite: true,
                tickInterval: 25,
              },
            ],
            tooltip: {
              borderColor: "#FFFFFF",
              backgroundColor: "#050505",
              color: "#d0dbee",
              borderWidth: 0,
              style: {
                color: "#d0dbee",
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: false,
                },
                pointPadding: 0.3,
                // colorByPoint: true,
              },
              showInLegend: true,
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            legend: {
              enabled: true,
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 24,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "GEC",
                data: [10],
                showInLegend: true,
              },
              {
                name: "Comedy",
                data: [23],
              },
              {
                name: "Reality show",
                data: [48],
              },
              {
                name: "News",
                data: [38],
              },
              {
                name: "Sports",
                data: [78],
              },
            ],
          },
        },
        {
          label: "Newspaper",
          value: 15,
          backgroundColor: "#5e35b1",
          type: "range",
          stripData: [
            {
              label: "Business News Daily",
              value: 35,
              backgroundColor: "#5e35b1",
            },
            {
              label: "Business News Daily",
              value: 23,
              backgroundColor: "#5e35b1",
            },
            {
              label: "Magazines",
              value: 55,
              backgroundColor: "#5e35b1",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "Billboards",
          value: 15,
          backgroundColor: "#00acc1",
          type: "range",
          stripData: [
            {
              label: "Billboard",
              value: 35,
              backgroundColor: "#00acc1",
            },
            {
              label: "Transit",
              value: 23,
              backgroundColor: "#00acc1",
            },
            {
              label: "Digital OOH",
              value: 25,
              backgroundColor: "#00acc1",
            },
            {
              label: "Street Furniture",
              value: 19,
              backgroundColor: "#00acc1",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "PR",
          value: 15,
          backgroundColor: "#00897b",
          type: "range",
          stripData: [
            {
              label: "Digital",
              value: 35,
              backgroundColor: "#00897b",
            },
            {
              label: "Television",
              value: 23,
              backgroundColor: "#00897b",
            },
            {
              label: "Event sponsership",
              value: 5,
              backgroundColor: "#00897b",
            },
            {
              label: "Radio",
              value: 9,
              backgroundColor: "#00897b",
            },
            {
              label: "Newspaper",
              value: 15,
              backgroundColor: "#00897b",
            },
            {
              label: "Billboards",
              value: 15,
              backgroundColor: "#00897b",
            },
            {
              label: "PR",
              value: 15,
              backgroundColor: "#00897b",
            },
            {
              label: "Cinema",
              value: 13,
              backgroundColor: "#00897b",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "Cinema",
          value: 13,
          backgroundColor: "#fb8c00",
          type: "range",
          stripData: [
            {
              label: "Digital",
              value: 35,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Television",
              value: 23,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Event sponsership",
              value: 5,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Radio",
              value: 9,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Newspaper",
              value: 15,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Billboards",
              value: 15,
              backgroundColor: "#fb8c00",
            },
            {
              label: "PR",
              value: 15,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Cinema",
              value: 13,
              backgroundColor: "#fb8c00",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
      ],
      // digitalExpandData: [
      //   {
      //     name: "Video Campaigns",
      //     pointWidth: 20,
      //     data: [
      //       {
      //         x: Date.UTC(2014, 10, 21),
      //         x2: Date.UTC(2014, 11, 2),
      //         y: 0,
      //         pointWidth: 16,
      //         pointPadding: 0,
      //         legendColor: "black",
      //       },
      //     ],
      //     dataLabels: {
      //       enabled: false,
      //     },
      //   },
      //   {
      //     name: "Engagement Campaigns",
      //     pointWidth: 20,
      //     data: [
      //       {
      //         x: Date.UTC(2014, 11, 2),
      //         x2: Date.UTC(2014, 11, 10),
      //         y: 1,
      //         pointWidth: 16,
      //         pointPadding: 0,
      //       },
      //     ],
      //     dataLabels: {
      //       enabled: false,
      //     },
      //   },
      //   {
      //     name: "Lead Ads",
      //     pointWidth: 20,
      //     data: [
      //       {
      //         x: Date.UTC(2014, 11, 10),
      //         x2: Date.UTC(2014, 11, 23),
      //         y: 2,
      //         pointWidth: 16,
      //         pointPadding: 0,
      //       },
      //     ],
      //     dataLabels: {
      //       enabled: false,
      //     },
      //   },
      //   {
      //     name: "Click to website",
      //     pointWidth: 20,
      //     color: "red",
      //     data: [
      //       {
      //         x: Date.UTC(2014, 11, 23),
      //         x2: Date.UTC(2014, 11, 30),
      //         y: 3,
      //         pointWidth: 16,
      //         pointPadding: 0,
      //       },
      //     ],
      //     dataLabels: {
      //       enabled: false,
      //     },
      //   },
      // ],
      digitalData: [
        {
          label: "Google Analytics",
          value: 35,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 20,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 8,
          backgroundColor: "#148f3a",
        },
        {
          label: "Linkedin",
          value: 9,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 15,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 13,
          backgroundColor: "#08a0e9",
        },
      ],
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 10, 21),
              x2: Date.UTC(2014, 11, 2),
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 2),
              x2: Date.UTC(2014, 11, 10),
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 10),
              x2: Date.UTC(2014, 11, 23),
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: Date.UTC(2014, 11, 23),
              x2: Date.UTC(2014, 11, 30),
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      contentExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 8),
              x2: Date.UTC(2014, 10, 0),
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 8),
              x2: Date.UTC(2014, 10, 0),
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 8),
              x2: Date.UTC(2014, 10, 0),
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: Date.UTC(2014, 11, 8),
              x2: Date.UTC(2014, 10, 0),
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      digitalBreakDown: false,
      contentData: [
        {
          label: "Paid Media",
          value: 35,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 77,
          backgroundColor: "#b5a9ff",
        },
      ],
      contentBreakDown: false,
      isSavedPlanDetail: true,
      saveplandropdown: [{ text: "Personal Space", id: "persolanspace" },{
          text: "Shared Folder",
          id: "Shared Folder",
        },],
      selectedSavedPlan: "",
      isSavedPlan: false,
      message: "",
    };
  },
  methods: {
    getDigitalBreakdown(newVal) {
      this.digitalBreakDown = newVal;
    },
    getContentBreakdown(newVal) {
      this.contentBreakDown = newVal;
    },
    hideSavedPlanDetail() {
      this.$modal.hide("newSavedPlanDetail");
    },
    savePlan() {
      this.$modal.show("newSavedPlanDetail");
    },
    selectSavePlan(e) {
      this.selectedSavedPlan = e;
    },
    savedPlan() {
      this.isSavedPlan = true;
      this.message = "Yay, our system has saved your plan successfully!";
      this.$modal.hide("newSavedPlanDetail");
      this.$refs.messages.fadeMe();
    },
  },
};
</script>

<style scoped>
.btn-effect {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  padding-left: 20px;
}
.btn-effect-valid {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect-valid i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover,
.btn-effect.active {
  background-color: #050505;
  color: #f3f3f3;
}
.chart-container {
  padding: 0 10px !important;
}
.border_top_divider,
.border_top_divider_last {
  border-top: 1px solid #ccc;
  margin-top: 50px;
}
.border_top_divider::after {
  content: "";
  display: block;
  position: absolute;
  top: 31%;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(224deg);
  left: 15%;
}
.border_top_divider_last::after {
  content: "";
  display: block;
  position: absolute;
  top: 37.8%;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(224deg);
  right: 25%;
}
.recomended_content {
  font-family: ProximaNovaRegular;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
}
.welcome--modal {
  z-index: 1112 !important;
}
.pr-10 {
  padding-right: 10rem !important;
}
.width-btn-save-plan {
  width: 208px !important;
}
.input-control {
  color: #222a37 !important;
  border: none;
  background: #eff5ff !important;
}
.arrow-left-btn {
  margin-left: auto;
  width: 74px;
}
.arrow-left-btn i {
  margin-top: 0 !important;
}
.intro__content {
  padding-right: 70px !important;
}
/deep/ .vm--modal {
  margin-top: 30px !important;
}
@media screen and (max-width: 640px) {
  .buttons-section {
    text-align: right;
  }
  p.Welcome-to-Data-Poem.ProximaNovaRegular {
    font-size: 2rem;
    text-align: center;
  }
  /deep/ .welcome--modal .vm--modal {
    width: 85% !important;
  }
  p.Together-let-us-hel.ProximaNovaRegular.mt-0.w-100 {
    padding: 0;
  }
  .intro__content {
    padding-right: 10px;
  }
  /deep/ .row.modal-padding {
    padding-top: 10px;
  }
  .col.p-0.intro__content {
    padding: 0 55px 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .buttons-section {
    text-align: right;
  }
}
</style>
